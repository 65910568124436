<template>
    <div v-if="word" class="price">
        <div class="price-title">
            <div>{{word.price.page_title[language]}}</div>
            <img src="./../assets/search.png" alt="">
        </div>
        <div class="price-list">
            <div class="search-input">
                <input @input="searchCountry" @focus="showSMSCountryList = true;smsKeyword = ''" @blur="hiddenSelect" :placeholder="word.price.keyword[language]" type="text" v-model="smsKeyword">
                <div v-show="showSMSCountryList" class="country-list">
                    <div v-show="item.show" v-for="item in country.sms" :key="item.countryNumber">
                        <div @click="choose(item)" style="width: 99%"><span v-if="item.countryNumber > 0">({{item.countryNumber}}) </span>{{item[key]}}</div></div>
                </div>
            </div>
            <div v-if="smsCountry">
                <div class="price-item-title">
                    <div>{{word.price.title[language]}}({{smsCountry[key]}})</div>
                    <div class="subtitle">{{word.price.subtitle[language]}}</div>
                </div>
                <div v-if="smsCountry" class="price-item-list">
                    <div></div>
                    <div>
                        <div class="price-item">{{word.price.opt_price[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">{{word.price.notifications_price[language]}}</div>
                    </div>
                </div>
                <div class="price-item-list">
                    <div>
                        <div class="price-item">{{word.price.usd[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item" v-if="smsCountry.OTP > 0">$<span>{{smsCountry.OTP}}</span>/{{word.price.unit[language]}}</div>
                        <div v-else>{{word.price.noPrice[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item" v-if="smsCountry.market > 0">$<span>{{smsCountry.market}}</span>/{{word.price.unit[language]}}</div>
                        <div v-else>{{word.price.noPrice[language]}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-list">
            <div class="search-input">
                <input @input="searchVoiceCountry" @focus="showVoiceCountryList = true;voiceKeyword = ''" @blur="hiddenVoiceSelect" :placeholder="word.price.keyword[language]" type="text" v-model="voiceKeyword">
                <div v-show="showVoiceCountryList" class="country-list">
                    <div v-show="item.show" v-for="item in country.voice" :key="item.countryNumber">
                        <div @click="chooseVoice(item)" style="width: 99%"><span v-if="item.countryNumber > 0">({{item.countryNumber}}) </span>{{item[key]}}</div></div>
                </div>
            </div>
            <div v-if="voiceCountry">
                <div class="price-item-title">
                    <div>{{word.price.voice_title[language]}}({{voiceCountry[key]}})</div>
                    <div class="subtitle">{{word.price.voicetitle[language]}}</div>
                </div>
                <div v-if="voiceCountry" class="price-item-list">
                    <div></div>
                    <div>
                        <div class="price-item">{{word.price.mobile[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">{{word.price.fix[language]}}</div>
                    </div>
                </div>
                <div class="price-item-list">
                    <div>
                        <div class="price-item">{{word.price.usd[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item" v-if="voiceCountry.mobile > 0">$<span>{{voiceCountry.mobile}}</span>/{{word.price.unitVoice[language]}}</div>
                        <div v-else>{{word.price.noPrice[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item" v-if="voiceCountry.fix > 0">$<span>{{voiceCountry.fix}}</span>/{{word.price.unitVoice[language]}}</div>
                        <div v-else>{{word.price.noPrice[language]}}</div>
                    </div>
                </div>
                <div class="price-item-list">
                    <div>
                        <div class="price-item">{{word.price.increment[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item" v-if="voiceCountry.mobile > 0"><span>{{voiceCountry.mobile_increment}}</span></div>
                    </div>
                    <div>
                        <div class="price-item" v-if="voiceCountry.fix > 0"><span>{{voiceCountry.fix_increment}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/request/request.js'
import base from '@/utils/request/base.js'
import word from './../assets/json/language.json'
import country from './../assets/json/country.json'
import 'animate.css';
export default {
    name: 'Price',
    components: {},
    data() {
        return {
            language: '',
            word: word,
            showSMSCountryList: false,
            showVoiceCountryList: false,
            country: {
                sms: [],
                voice: []
            },
            key: 'countryNameEn',
            smsKeyword: '',
            voiceKeyword: '',
            smsCountry: null,
            voiceCountry: null
        }
    },
    created() {
        this.language = localStorage.getItem('language')
        axios.get(`/global-price/getAllCountry`).then(res=>{
            this.country = res.data
            for(let item of this.country.sms) {
                item.show = true
            }
            for(let item of this.country.voice) {
                item.show = true
            }
            this.key = this.language == 'EN' ? 'countryNameEn' : 'countryName'
            this.smsKeyword = this.country.sms[0].countryNumber > 0 ? '(' + this.country.sms[0].countryNumber + ') ' + this.country.sms[0][this.key] : this.country.sms[0][this.key];
            this.voiceKeyword = this.country.voice[0].countryNumber > 0 ? '(' + this.country.voice[0].countryNumber + ') ' + this.country.voice[0][this.key] : this.country.voice[0][this.key];
            this.smsCountry = this.country.sms[0];
            this.voiceCountry = this.country.sms[0];
            this.voiceCountry = this.country.voice[0];
            this.getPrice(this.country.sms[0], 1)
            this.getPrice(this.country.voice[0], 2)
        })
    },
    methods: {
        hiddenSelect() {
            setTimeout(()=>{
                this.showSMSCountryList = false;
            }, 200)
        },
        hiddenVoiceSelect() {
            setTimeout(()=>{
                this.showVoiceCountryList = false;
            }, 200)
        },
        getPrice(country, type) {
            let url = `/global-price/getAll?country=` + country.countryNumber + `&productType=` + type
            axios.get(url).then(res=>{
                for(let item of res.data) {
                    if(item[this.key] == country[this.key]) {
                        if(type == 1) {
                            if(item.type == 1) {
                                country.OTP = item.price
                            }
                            if(item.type == 2) {
                                country.market = item.price
                            }
                        }
                        if(type == 2) {
                            if(item.type == 0) {
                                country.mobile = item.price
                                country.fix = item.price
                                country.mobile_increment = item.increment
                                country.fix_increment = item.increment
                            }
                            if(item.type == 1) {
                                country.fix = item.price
                                country.fix_increment = item.increment
                            }
                            if(item.type == 2) {
                                country.mobile = item.price
                                country.mobile_increment = item.increment
                            }
                        }
                    }
                }
            })
        },
        choose(e) {
            this.smsKeyword = e.countryNumber > 0 ? '(' + e.countryNumber + ') ' + e[this.key] : e[this.key];
            this.smsCountry = e;
            for(let item of this.country.sms) {
                item.show = true;
                if(e.countryNumber == item.countryNumber) {
                    this.getPrice(item, 1)
                }
            }
        },
        chooseVoice(e) {
            this.voiceKeyword = e.countryNumber > 0 ? '(' + e.countryNumber + ') ' + e[this.key] : e[this.key];
            this.voiceCountry = e;
            for(let item of this.country.voice) {
                item.show = true;
                if(e.countryNumber == item.countryNumber) {
                    this.getPrice(item, 2)
                }
            }
        },
        searchCountry() {
            for(let item of this.country.sms) {
                item.show = false;
                console.log(item)
                if(item.CN.toUpperCase().indexOf(this.smsKeyword.toUpperCase()) > -1 || item.EN.toUpperCase().indexOf(this.smsKeyword.toUpperCase()) > -1 || (item.countryNumber > 0 && item.countryNumber.toString().indexOf(this.smsKeyword) > -1)) {
                    item.show = true;
                }
            }
        },
        searchVoiceCountry() {
            for(let item of this.country.voice) {
                item.show = false;
                console.log(item)
                if(item.CN.toUpperCase().indexOf(this.voiceKeyword.toUpperCase()) > -1 || item.EN.toUpperCase().indexOf(this.voiceKeyword.toUpperCase()) > -1 || (item.countryNumber > 0 && item.countryNumber.toString().indexOf(this.voiceKeyword) > -1)) {
                    item.show = true;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .price-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin: 50px auto;
        font-size: 48px;
        font-weight: 800;
        img {
            width: 40%;
        }
    }
    .search-input {
        height: 50px;
        padding: 10px 0;
        width: 35%;
        min-width: 450px;
        margin-bottom: 50px;
        position: relative;
        .country-list {
            position: absolute;
            top: 55px;
            left: 16px;
            max-height: 200px;
            padding: 0 0 2px;
            width: 100%;
            background-color: #fff;
            overflow: auto;
            box-shadow: 0px 5px 5px rgba(0,0,0,.2);
            div {
                cursor: pointer;
                padding: 5px;
                &:hover {
                    background-color: #E2EFF7;
                }
            }
        }
        input {
            border-radius: 8px;
            border: 1px solid #e2e2e2;
            height: 100%;
            width: 100%;
            padding: 0 10px;
            font-size: 18px;
            &:focus {
                outline:none;
                border: 1px solid #e2e2e2;
            }
        }
    }
    .price-list {
        margin-bottom: 100px;
        background-color: #FBFBFB;
        padding: 40px 60px 80px;
        width: 70%;
        margin-left: 15%;
        >div {
            padding: 0 16px;
            .price-item-title {
                font-size: 40px;
                font-weight: bold;
                margin-bottom: 60px;
                .subtitle {
                    font-size: 14px;
                    font-weight: normal;
                    margin-top: 10px;
                    color: #999;
                }
            }
            .price-item-list {
                width: 100%;
                display: flex;
                align-items: flex-end;
                >div {
                    width: 30%;
                    flex-shrink: 0;
                    .price-item {
                        margin-top: 20px;
                        span {
                            color: #108CCF;
                            margin: 0 4px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
</style>
